@tailwind base;
@tailwind components;
@tailwind utilities;

[type="text"],
input:where(:not([type])),
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  appearance: none;
  background-color: transparent;
  border-color: unset;
  border-width: 0;
  border-radius: unset;
  padding: unset;
  font-size: unset;
  line-height: unset;
}

/* Override default focus colors for tailwindcss-forms https://github.com/tailwindlabs/tailwindcss-forms */
[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="search"]:focus,
[type="checkbox"]:focus,
[type="radio"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  --tw-ring-color: transparent;
  border-color: transparent;
}

li:focus-visible {
  outline: none;
}

li:hover {
  cursor: pointer;
}

@layer components {

  /*-------------------------------------
  Container CSS
--------------------------------------*/
  .container-xs {
    @apply w-full mx-auto max-w-[1043px];
  }

  .container-sm {
    @apply w-full mx-auto max-w-[1344px];
  }

  .container-md {
    @apply w-full mx-auto max-w-[1352px];
  }

  .container-lg {
    @apply w-full mx-auto max-w-[1408px];
  }
}

@layer utilities {
  .bg-mesh-gradient {
    background-color: hsla(208, 100%, 65%, 1);
    background-image:
      radial-gradient(at 0% 29%, hsla(0, 0%, 100%, 0.5) 0px, transparent 50%),
      radial-gradient(at 93% 58%, hsla(197, 74%, 72%, 1) 0px, transparent 50%),
      radial-gradient(at 13% 79%, hsla(240, 35%, 60%, 0.3) 0px, transparent 50%),
      radial-gradient(at 63% 12%, hsla(240, 35%, 60%, 0.36) 0px, transparent 50%),
      radial-gradient(at 80% 93%, hsla(0, 0%, 100%, 0.56) 0px, transparent 50%);
  }
}

.button-white {
  @apply flex justify-center items-center h-full p-4 font-semibold text-blue-500 text-sm border border-blue-500 bg-white-A700 rounded-lg;
  @apply hover:bg-blue-100 transform transition-transform duration-300 ease-in-out hover:scale-105 active:scale-100;
}

.button-blue {
  @apply flex justify-center items-center h-full p-4 font-inter font-semibold text-white-A700 border border-gray-200 bg-blue-500 rounded-lg;
  @apply hover:bg-blue-400 transform transition-transform duration-300 ease-in-out hover:scale-105 active:scale-100;
}

.course-card-hover {
  transition: transform 0.3s ease, filter 0.3s ease;
}

.course-card-hover:hover {
  transform: scale(1.05);
  filter: brightness(90%);
}

.donut {
  transform: rotate(-90deg);
  transform-origin: center;
}

.donut-hole {
  stroke-width: 3;
}

.donut-ring {
  stroke-width: 3;
}

.donut-segment {
  stroke-linecap: round;
}

.rotate-180 {
  transform: rotate(180deg);
}

.fixed {
  z-index: 1000;
}

.bg-black.bg-opacity-50 {
  background-color: rgba(0, 0, 0, 0.5);
}

.input-group {
  display: flex;
  gap: 10px;
}

.input-container {
  flex: 1;
}

.input-style {
  width: 100%;
  border: 1px solid black;
  border-radius: 4px;
  padding: 8px;
}

.black-shadow {
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.time-period:hover {
  background-color: #e0e0e0;
  border-radius: 50%;
  padding: 5px;
}

.time-period {
  position: relative;
}

.hover-target::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  background-color: #e0e0e0;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.3s ease-in-out;
}

.hover-target:hover::before {
  transform: translate(-50%, -50%) scale(1);
}

#rectangle-9 {
  position: relative;
}

#rectangle-9::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.from-white {
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.to-transparent {
  --tw-gradient-to: transparent;
}

.flex-container {
  display: flex;
  align-items: start;
  justify-content: flex-start;
  padding-left: 0;
}

.mb-25 {
  margin-bottom: 25px;
}

.shift-left {
  margin-left: -10px;
}

.modal-size {
  width: 200px;
  height: 300px;
}

.user-select-text {
  user-select: text;
  /* Enable text selection */
}