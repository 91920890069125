/*noinspection CssUnknownTarget*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.bulleted-list {
    @apply list-disc px-16;
}

.numbered-list {
    @apply list-decimal px-16;
}

.list-item {
    @apply pt-4;
}

.link {
    @apply underline;
}