progress[value] {
  appearance: none;
  width: 100%;
  height: 1rem;
}

progress[value]::-webkit-progress-bar {
  background-color: #e2e8f0; /* bg-gray-200 */
  border-radius: 0.375rem; /* rounded-lg */
}

progress[value]::-webkit-progress-value {
  background-color: #1d4ed8; /* bg-blue-700 */
  border-radius: 0.375rem; /* rounded-lg */
}

progress[value]::-moz-progress-bar {
  background-color: #1d4ed8; /* bg-blue-700 */
  border-radius: 0.375rem; /* rounded-lg */
}